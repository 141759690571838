import { Component, AfterViewInit } from '@angular/core';
import { FeatureCardModel } from '../feature-cards/feature-card-model';
import Cocoen from '../../../node_modules/cocoen/src/js/cocoen';

interface JQuery {
	cocoen: CocoenPlugin;
}

interface CocoenPlugin {
	// tslint:disable-next-line:callable-types
	(this: JQuery): void;
}

@Component({
	selector: 'app-product-page',
	templateUrl: './product-page.component.html',
	styleUrls: ['./product-page.component.scss']
})

export class ProductPageComponent implements AfterViewInit {

	features1:Array<FeatureCardModel> = [
		{ image: '/assets/images/common-steps.svg', title: 'Общие шаги', text: 'Применяйте рефакторинг в тестировании. Поддерживайте артефакты тестирования в актуальном состоянии, выделяйте общие шаги. Вносите изменения в один шаг и все связанные изменятся автоматически.' },
		{ image: '/assets/images/checklists.svg', title: 'Чек листы', text: 'Сократите время на описания тестовых сценариев. TEST IT позволяет разрабатывать чек-листы и добавлять их в план тестирования. После релиза система подскажет о техническом долге.' },
		{ image: '/assets/images/balance.svg', title: 'Балансировщик нагрузки', text: 'Распределение сценариев между тестировщиками может происходить автоматически. TEST IT поможет равномерно назначить задачи и сэкономить ваше время.' }
	];

	features2: Array<FeatureCardModel> = [
		{ image: '/assets/images/history.svg', title: 'История изменений', text: 'Весь жизненный цикл артефакта тестирования представлен в удобном формате. Вы сможете увидеть его результаты в разрезе версий тестируемого ПО.' },
		{ image: '/assets/images/integration.svg', title: 'Интеграция с Jira', text: 'Двухсторонняя синхронизация устанавливает связь между артефактами тестирования, дефектами и требованиями. Отслеживайте покрытие требований тестовыми сценариями и автотестами. Больше ни один баг не останется без внимания.' },
		{ image: '/assets/images/configuration.svg', title: 'Конфигурации', text: 'Наша система помогает управлять тестовыми наборами , создавая кроссбраузерные или кроссплатформенный конфигурации.' }
	];

	features3: Array<FeatureCardModel> = [
		{ image: '/assets/images/comments-and-att.svg', title: 'Комментарии и вложения', text: 'Общение внутри тестового сценария упрощает жизнь вам и коллегам. Прикрепляйте файлы, подписывайтесь на изменения, комментируйте, просматривайте изображения внутри системы без необходимости скачивания.' },
		{ image: '/assets/images/qucksearch.svg', title: 'Быстрый поиск', text: '• Истории изменений артефакта тестирования.<br>\
		• Результатов прохождения с разбивкой на версии продукта.<br>\
		• Связанных дефектов и требований. ' },
		{ image: '/assets/images/tags.svg', title: 'Теги', text: 'Функционал тегирования тестовых сценариев и чек- листов позволяет сформировать план тестирования за считанные секунды.' }
	];


	features4: Array<FeatureCardModel> = [
		{ image: '/assets/images/roles.png', title: 'Ролевая модель', text: 'Управление ролями позволяет ограничивать доступ к проектам и системе. Разделять права доступа для каждого пользователя или группы и многое другое. ' },
		{ image: '/assets/images/api.svg', title: 'Управление запусками автотестов', text: 'Публичное API позволяет управлять запусками автоматических тестов и синхронизировать результаты с любой системой.' },
		{ image: '/assets/images/doc-generation.png', title: 'Генерация тествой документации', text: 'Создавайте тестовые сценарии автоматически из исходного кода. Разработали автотест, уже через несколько секунд он появится в TEST IT.' }
	];

	ngAfterViewInit() {
		let c = new Cocoen(document.querySelector('.cocoen'));
	}


}
