import { Component, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { PriceOptions } from '../price-page/price-options';
import { NgForm } from '@angular/forms';
import { BuyFormModel } from './buy-form-model';

@Component({
	selector: 'app-buy-form',
	templateUrl: './buy-form.component.html',
	styleUrls: ['./buy-form.component.scss']
})

export class BuyFormComponent implements AfterContentInit {
	ngAfterContentInit(): void {
		this.model.employeeCount = this.priceOption;
	}
	
	@Input() priceOption: string;
	@Input() visible: Boolean;
	@Output() closed = new EventEmitter<boolean>();

	selectOptions = PriceOptions.map(o => o.value);

	model:BuyFormModel = new BuyFormModel();

	close() {
		this.closed.emit();
	}

	submitForm(buyForm:NgForm) {
		if (buyForm.valid) {
			//TODO: Submit here
		}
	}

}
