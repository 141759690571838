import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-subscribe-form',
	templateUrl: './subscribe-form.component.html',
	styleUrls: ['./subscribe-form.component.scss']
})
export class SubscribeFormComponent {

	model = {
		name: "",
		company: "",
		email: "",
		phone: ""
	};

	submitForm(form:NgForm) {
		if (form.submitted) {
			//TODO: Submit data here
		}
	}

}
