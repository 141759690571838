import {
	Component,
	ContentChildren,
	QueryList,
	AfterContentInit,
	ViewChild,
	ComponentFactoryResolver,
	ViewContainerRef
} from '@angular/core';
import { MediaStackItemComponent } from '../media-stack-item/media-stack-item.component';

@Component({
	selector: 'app-media-stack',
	templateUrl: './media-stack.component.html',
	styleUrls: ['./media-stack.component.scss']
})
export class MediaStackComponent implements AfterContentInit {

	@ContentChildren(MediaStackItemComponent) tabs: QueryList<MediaStackItemComponent>;

	ngAfterContentInit() {
		let activeTabs = this.tabs.filter((tab) => tab.active);

		if (activeTabs.length === 0) {
			this.selectTab(this.tabs.first);
		}
	}

	selectTab(tab: MediaStackItemComponent) {
		// deactivate all tabs
		this.tabs.toArray().forEach(tab => tab.active = false);

		// activate the tab the user has clicked on.
		tab.active = true;
	}

}
