import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
	selector: 'app-header-main',
	templateUrl: './header-main.component.html',
	styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent {
	menuOpen: boolean = false;
	
	constructor(private scroller: ViewportScroller) {

	}

	navigateTo(id:string) {
		this.scroller.scrollToAnchor(id);
	}
}
