import { Component } from '@angular/core';
import { FeatureCardModel } from '../feature-cards/feature-card-model';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
	featureItems: Array<FeatureCardModel> = [
		{
			title: "Автотесты",
			text: "Отслеживайте процесс выполнения автоматических тестов. Вы можете выбирать и запускать автоматизированные тесты— результаты отобразятся в едином отчёте через несколько секунд.",
			image: "/assets/images/auto-tests.svg"
		},
		{
			title: "Балансировщик нагрузки",
			text: "Распределение сценариев между тестировщиками может происходить автоматически. TEST IT поможет равномерно назначить задачи и сэкономить ваше время.",
			image: "/assets/images/balance.svg"
		},
		{
			title: "Конфигурации",
			text: "Наша система помогает управлять тестовыми наборами , создавая к россбраузерные или кроссплатформенный конфигурации.",
			image: "/assets/images/configuration.svg"
		},
		{
			title: "Персонализация",
			text: "TEST IT дает пользователю возможность адаптировать систему под себя. Меняйте стили, выбирайте аватарки, поднимайте настроение себе и коллегам.",
			image: "/assets/images/customization.svg"
		},
		{
			title: "История изменений",
			text: "Весь жизненный цикл артефакта тестирования представлен в удобном формате. Вы сможете увидеть его результаты в разрезе версий тестируемого ПО.",
			image: "/assets/images/history.svg"
		},
		{
			title: "Библиотека тестов",
			text: "Библиотека тестов помогает собирать знания о работе продуктов и делиться ими с новыми сотрудниками. TEST IT структурирует и хранит все тестовые сценарии по каждому проекту.",
			image: "/assets/images/tests-lib.png"
		}
	];;
}
