import { Component } from '@angular/core';
import { PriceOptions } from './price-options';

@Component({
	selector: 'app-price-page',
	templateUrl: './price-page.component.html',
	styleUrls: ['./price-page.component.scss']
})

export class PricePageComponent {

	options = PriceOptions;

	selectedOption = null;//this.options[0];
	formVisible: boolean = false;

	constructor() {
		this.selectItem(this.options[0]);
	}

	selectItem(item) {
		this.selectedOption = item;
	}

	openForm() {
		this.formVisible = true;
	}

	closeForm() {
		this.formVisible = false;
	}
}