import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eula-page',
  templateUrl: './eula-page.component.html',
  styleUrls: ['./eula-page.component.scss']
})
export class EulaPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
