import { Component, Input } from '@angular/core';
import {FeatureCardModel} from "./feature-card-model";

@Component({
	selector: 'app-feature-cards',
	templateUrl: './feature-cards.component.html',
	styleUrls: ['./feature-cards.component.scss']
})
export class FeatureCardsComponent {

	@Input() items:Array<FeatureCardModel>; 

}
