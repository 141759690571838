import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from './home-page/home-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { PricePageComponent } from './price-page/price-page.component';
import { EulaPageComponent } from './eula-page/eula-page.component';


const routes: Routes = [
	{
		path: '',
		component: HomePageComponent
	},

	{
		path: 'product',
		component: ProductPageComponent
	},

	{
		path: 'price',
		component: PricePageComponent
	},

	{
		path: 'eula',
		component: EulaPageComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
