import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-media-stack-item',
  templateUrl: './media-stack-item.component.html',
  styleUrls: ['./media-stack-item.component.scss']
})
export class MediaStackItemComponent {

  @Input('img') image: string;
  @Input() active = false;

}
