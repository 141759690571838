import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeatureCardsComponent } from './feature-cards/feature-cards.component';
import { FooterMainComponent } from './footer-main/footer-main.component';
import { HeaderMainComponent } from './header-main/header-main.component';
import { MediaStackComponent } from './media-stack/media-stack.component';
import { MediaStackItemComponent } from './media-stack-item/media-stack-item.component';
import { SubscribeFormComponent } from './subscribe-form/subscribe-form.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { PricePageComponent } from './price-page/price-page.component';
import { BuyFormComponent } from './buy-form/buy-form.component';
import { SafeHtml } from './safe-html';
import { EulaPageComponent } from './eula-page/eula-page.component';

@NgModule({
	declarations: [
		AppComponent,
		FeatureCardsComponent,
		FooterMainComponent,
		HeaderMainComponent,
		MediaStackComponent,
		MediaStackItemComponent,
		SubscribeFormComponent,
		HomePageComponent,
		ProductPageComponent,
		PricePageComponent,
		BuyFormComponent,
		SafeHtml,
		EulaPageComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
