import { Component, OnInit } from '@angular/core';
import * as AOS from "aos";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'testIt';

	ngOnInit() {
		AOS.init({
			delay: 200,
			offset: 200,
			duration: 400
		});
	}
}
